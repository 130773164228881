import {Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {BonReceptionLigneDTO} from '../../../../core/dtos/bon-reception-ligne-dto';
import {BonReceptionDTO} from '../../../../core/dtos/bon-reception-dto';
import {BonReceptionService} from '../../../../core/services/entities/bon-reception.service';
import {Subscription} from 'rxjs';
import {catchError, switchMap} from 'rxjs/operators';
import {UtilsService} from '../../../../core/utils/utils.service';
import {BonReceptionLigneService} from '../../../../core/services/entities/bon-reception-lignes.service';
import {cloneDeep as _cloneDeep, find as _find, forEach} from 'lodash'
import {UniteDeProduction__ZoneDeStockageDTO} from '../../../../core/dtos/unite-de-production__zone-stockage-dto';
import {
  DATEPICKER_FR,
  MODE_RECEPTION_STATUT,
  MSG_KEY,
  MSG_SEVERITY,
  RECEPTION_STATUT,
  UI_COLORS
} from '../../../../core/constants';
import {AppellationDTO} from '../../../../core/dtos/appellations-dto';
import {AppellationsService} from '../../../../core/services/entities/appellations.service';
import {MotifsNonConformiteService} from '../../../../core/services/entities/motifs-non-conformite.service';
import {DxDataGridComponent} from 'devextreme-angular';
import {MenuItem} from 'primeng/api';
import {MotifNonConformiteDTO} from '../../../../core/dtos/motif-non-conformite-dto';
import {LitigeDTO} from '../../../../core/dtos/litige-dto';
import {LitigesService} from '../../../../core/services/entities/litiges.service';
import {DATAGRID_ROW_TYPES} from '../../../../core/services/technique/devextreme.service';
import {OrigineDTO} from "../../../../core/dtos/origine-dto";
import {OrigineService} from "../../../../core/services/entities/origines-service";
import {ToastService} from "../../../../core/services/technique/toast.service";
import {Auth2Service} from "../../../../core/services/security/auth2.service";
import {FacturationService} from "../../../../core/services/entities/facturation.service";
import {Router} from "@angular/router";
import {NavigationService} from "../../../../core/services/entities/navigation.service";
import {GraphQLService} from "../../../../core/services/technique/graphql.service";
import {OrigineCarneDTO} from "../../../../core/dtos/origine-carne-dto";
import {OrigineCarneComponent} from "../../../../shared/ui/origine-carne/origine-carne.component";
import {confirm} from "devextreme/ui/dialog";
import {ImageBase64DTO} from "../../../../core/dtos/images/ImageBase64DTO";
import {image} from "d3";


@Component({
  selector: 'yo-dialog-article-reception-grid',
  templateUrl: './dialog-article-reception-grid.component.html',
  styleUrls: ['./dialog-article-reception-grid.component.scss']
})
export class DialogArticlesReceptionGridComponent implements OnInit, OnDestroy {

  @Input() displayDialog: boolean;
  @Input() displayEdit: boolean;
  @Output() onClose = new EventEmitter<boolean>();
  @Output() onSave = new EventEmitter<BonReceptionDTO>();

  @ViewChild('grid') grid: DxDataGridComponent;
  @ViewChild('dialogOrigineCarne') dialogOrigineCarne: OrigineCarneComponent;

  isDisplayDialogOrigineCarne: boolean = false;

  editorOptions: any;
  isModif: boolean = false;
  lignesBonReception: BonReceptionLigneDTO[] = [];
  selectedBonReceptionLignes: BonReceptionLigneDTO[] = [];
  bonReception: BonReceptionDTO;
  udpZdsList: UniteDeProduction__ZoneDeStockageDTO[] = [];

  appelationList: AppellationDTO[] = [];
  motifNonConformiteList: MotifNonConformiteDTO[] = [];
  litigeList: LitigeDTO[] = [];
  origineList: OrigineDTO[] = [];

  subBonReceptionLigneDeleted: Subscription;
  subBonReception: Subscription;

  subRightsBill: Subscription;

  columnOnfocus: string;

  montantHT = 0;

  receptionStatutCode = RECEPTION_STATUT;
  modeReceptionStatutCode = MODE_RECEPTION_STATUT;

  itemsReceptions: any[] = [];

  fr: any = DATEPICKER_FR;

  hasCommandesFacturesRight: boolean;

  subBindReception: Subscription;

  subNumbersBill: Subscription;

  numBill: string;

  openDialogBindBill: boolean = false;

  numbersBills: string[] = ['Nouveau n° de facture'];

  /**
   * Numéro de facture associée au bon de réception courant
   */
  numberInvoice: string;

  subAppellationsGraphQl: Subscription;

  isImagePopupOpened: boolean = false;

  selectedBonReceptionLigne: BonReceptionLigneDTO;
  images: ImageBase64DTO[];


  constructor(public bonReceptionSvc: BonReceptionService,
              private facturationSvc: FacturationService,
              public utils: UtilsService,
              public bonReceptionLigneSvc: BonReceptionLigneService,
              public appellationSvc: AppellationsService,
              public litigeSvc: LitigesService,
              public origineSvc: OrigineService,
              public mncSvc: MotifsNonConformiteService,
              private toastSvc: ToastService,
              private auth2Svc: Auth2Service,
              private navigationSvc: NavigationService,
              private graphQlSvc: GraphQLService) {
  }

  ngOnInit() {
    this.fetchNumbersBill();
    this.initData();
    this.initReceptionItemButton();
    this.initRights();
  }

  ngOnDestroy() {
    this.utils.unsubscribe(this.subBonReception);
    this.utils.unsubscribe(this.subBonReceptionLigneDeleted);
    this.utils.unsubscribe(this.subRightsBill);
    this.utils.unsubscribe(this.subBindReception);
    this.utils.unsubscribe(this.subNumbersBill);
    this.utils.unsubscribe(this.subAppellationsGraphQl);
  }

  initData = () => {
    // reagir à la modification des lignes de bon reception (si suppression d'un lot, les lignes sont recalculées)
    this.subBonReceptionLigneDeleted = this.bonReceptionLigneSvc.deleteOne$.subscribe(response => {
      this.lignesBonReception = this.lignesBonReception.filter(item => item.id != response.id);
      this.bonReception.bonReceptionLigneList = this.lignesBonReception;
      this.getMontants();
    });

    this.subBonReception = this.bonReceptionSvc.bonReceptionGridDxView$.pipe(
      switchMap((bonReception: BonReceptionDTO) => {
        this.bonReception = bonReception;
        this.initReceptionItemButton();
        this.facturationSvc.fetchNumberInvoiceFromOrderReceipt(this.bonReception.id)
          .subscribe((response) => this.numberInvoice = response.one)
        return this.bonReceptionSvc.getArticles(bonReception);
      }),
      catchError(err => this.utils.handleError(err))
    ).subscribe(response => {
      this.bonReception = response.one;
      this.lignesBonReception = response.one.bonReceptionLigneList;
      this.initReceptionItemButton();

      if (this.lignesBonReception && this.lignesBonReception.length > 0) {

        //recuperation list dropdwon pour les champs editable du tableau devExtreme
        this.udpZdsList = _cloneDeep(this.bonReceptionLigneSvc.initUdpZdsList(this.lignesBonReception[0]));

        this.subAppellationsGraphQl = this.graphQlSvc.sendQuery(`
          {
            allAppellations(filters: {
              siteIds: [${this.auth2Svc.utilisateur.sites.map(s => s.id)}],
              actif: true
            }) {
                id,
                code,
                libelle,
                actif,
                durable,
                bio,
                site {
                    id,
                    libelle,
                },
            }
          }
        `).subscribe(res => {
          this.appelationList = res.allAppellations;
          this.bonReceptionLigneSvc.mapToDxAppellationList(this.lignesBonReception, this.appelationList);
        });
        this.appelationList = _cloneDeep(this.appellationSvc.appellationsEnvironnement);
        this.motifNonConformiteList = _cloneDeep(this.mncSvc.motifsNonConfoEnvironnement);
        this.litigeList = _cloneDeep(this.litigeSvc.litigesEnvironnements);
        this.origineList = _cloneDeep(this.origineSvc.originesEnvironnement);

        this.bonReceptionLigneSvc.mapToDxMotifNonConfoList(this.lignesBonReception, this.motifNonConformiteList);
        this.bonReceptionLigneSvc.mapToDxLitigeList(this.lignesBonReception, this.litigeList);

      } else {
        this.udpZdsList = [];
        this.appelationList = [];
        this.motifNonConformiteList = [];
        this.litigeList = [];
        this.origineList = [];
      }

      this.editorOptions = {
        itemTemplate: "zonesStockageCellTemplate"
      };

      // fix, on transforme la dlc du back (nombre) en date
      this.lignesBonReception.forEach(item => item.dlc = this.utils.convertNumberDateToDate(item.dlc));

      this.displayDialog = true;
    });
  };

  initReceptionItemButton = () => {
    this.itemsReceptions = [
      {
        text: 'En cours',
        icon: 'fas fa-sync-alt',
        command: (event) => this.updateBonReceptionLigneList(this.modeReceptionStatutCode.EN_COURS, false)
      },
      {
        text: 'Partielle',
        disabled: this.bonReceptionSvc.isQuantityMinimumReceived(this.bonReception),
        icon: 'fas fa-receipt',
        command: (event) => this.updateBonReceptionLigneList(this.modeReceptionStatutCode.RECEPTION_PARTIELLE, false)
      },
      {
        text: 'Complète',
        icon: 'fas fa-check',
        command: (event) => this.updateBonReceptionLigneList(this.modeReceptionStatutCode.TERMINE, false)
      },
      {
        text: 'Complète avec facture',
        icon: 'fas fa-check',
        command: (event) => this.updateBonReceptionLigneList(this.modeReceptionStatutCode.TERMINE, true)
      }];
  };

  initRights = () => {
    this.subRightsBill = this.auth2Svc.hasCommandesFactures$
      .subscribe(response => this.hasCommandesFacturesRight = response);
  }

  getMontants = () => {
    this.bonReceptionSvc.updateMontantsEtFraisBonReception(this.bonReception).subscribe(res => {
      const br: BonReceptionDTO = res.one;
      this.bonReception.montantTotalHT = br.montantTotalHT;
      this.bonReception.montantTotalTTC = br.montantTotalTTC;
      this.bonReception.francoDePortCout = br.francoDePortCout;
    });
  };

  closeDialog: () => void = async () => {
    if (this.isModif) {
      let res = await confirm('Êtes-vous sûr de vouloir quitter sans enregistrer vos modifications ?', "Quitter le bon de réception");
      if (res) {
        this.isModif = false;
        this.displayDialog = false;
        this.onClose.emit(this.displayDialog);
      }
    } else {
      this.displayDialog = false;
      this.onClose.emit(this.displayDialog);
    }
  };


  getTooltipIcon = (value: string) => {
    switch (value) {
      case this.receptionStatutCode.NOUVEAU : {
        return 'Nouveau';
      }
      case this.receptionStatutCode.EN_COURS : {
        return 'En cours';
      }
      case this.receptionStatutCode.RECEPTION_PARTIELLE : {
        return 'Réception partielle';
      }
      case this.receptionStatutCode.TERMINE : {
        return 'Terminé';
      }
    }
  };

  isDisabled = () => {
    if (this.bonReception && this.displayEdit)
      return !this.canEdit();

    return true;
  };

  onRowUpdated = (event: any) => {
    this.isModif = true;
    switch (this.columnOnfocus) {
      case 'quantiteRecue':
        if (event.data) {
          this.onChangeQuantiteRecue(event.data);
        }
        break;

      case 'prixUnitaireFacture':
        if (event.data) {
          this.onChangeQuantiteRecue(event.data);
        }
        break;

      case 'idZds':
        if (event.data) {
          this.OnChangeUdpZds(event.data);
        }
        break;
      case 'idOrigine':
        if (event.data) {
          this.OnChangeOrigine(event.data);
        }
        break;
    }
    this.initReceptionItemButton();
  };


  setColumnOnFocus = (event: any) => {
    if (!this.utils.isNullOrEmpty(event.column)) {
      this.columnOnfocus = event.column.dataField;
    }
  };

  onChangeQuantiteRecue = async (bonReceptionLigne: BonReceptionLigneDTO) => {
    this.isModif = true;
    this.bonReceptionSvc.updateMontantsBonReceptionLigne(bonReceptionLigne)
      .subscribe(res => {
        bonReceptionLigne.prixUnitaireFacture = res.one.prixUnitaireFacture;
        bonReceptionLigne.quantiteRecue = res.one.quantiteRecue;
        bonReceptionLigne.totalFacture = res.one.totalFacture;
        bonReceptionLigne.totalFactureTTC = res.one.totalFactureTTC;
        this.getMontants();
      });
  };

  addLotArticle = (bonReceptionLigne: BonReceptionLigneDTO) => {
    this.isModif = true;
    const cloneBonReceptionLigne = _cloneDeep(bonReceptionLigne);
    cloneBonReceptionLigne.lotManuel = true;
    delete cloneBonReceptionLigne.dxAppellationList;
    delete cloneBonReceptionLigne.dxLitigeList;
    delete cloneBonReceptionLigne.dxMotifNonConfoList;

    // generer une ligne de bon reception à vide
    this.bonReceptionLigneSvc.initBonReceptionLigne(cloneBonReceptionLigne).subscribe(response => {

      if (this.utils.isCollectionNullOrEmpty(this.lignesBonReception)) {
        this.lignesBonReception = [];
      }

      // inserer juste apres le lot sur lequel on a demandé l'ajout
      for (let i = 0; i < this.lignesBonReception.length; i++) {
        const lbr = this.lignesBonReception[i];
        if (lbr.id === bonReceptionLigne.id) {
          const newBrl = response.one;
          newBrl.dlc = this.utils.convertNumberDateToDate(newBrl.dlc, true);
          this.lignesBonReception.splice(i + 1, 0, newBrl);
          this.lignesBonReception = _cloneDeep(this.lignesBonReception);
          break;
        }
      }
      this.bonReception.bonReceptionLigneList = this.lignesBonReception;
      this.getMontants();
    });
  };

  /**
   *
   * @param updateMode 1= Mise à jour, 2 = Reception partielle, 3 = Reception complete
   * @param openFactureOnSuccess Est-ce que la popup d'assignation de facture doit-être ouverte après la sauvegarde ?
   */
  updateBonReceptionLigneList = (updateMode: number, openFactureOnSuccess: boolean) => {
    this.isModif = true;
    let valid = true;

    if (updateMode === this.modeReceptionStatutCode.RECEPTION_PARTIELLE || updateMode === this.modeReceptionStatutCode.TERMINE) {

      // on verifie que les zones de stockage sont bien renseignées si la quantité recue est > 0
      let zdsExists = true;
      for (const brligne of this.lignesBonReception) {
        if (!this.utils.isNumberGt0(brligne.idUdpZds) || this.utils.isNullOrEmpty(brligne.idUdpZds)) {
          zdsExists = false;
          break;
        }
      }
      if (!zdsExists) {
        this.toastSvc.displayToast(MSG_KEY.ROOT, MSG_SEVERITY.WARNING, 'Veuillez renseignez les zones de stockage avant de réaliser une réception partielle ou complète.');
        valid = false;
      }
    }

    if (valid) {
      // dxModel en BonReceptionLigneModel
      this.lignesBonReception = this.bonReceptionLigneSvc.mapToBonReceptionLigneAppellationList(this.lignesBonReception);
      this.lignesBonReception = this.bonReceptionLigneSvc.mapToBonReceptionLigneMotifNonConfoList(this.lignesBonReception);
      this.lignesBonReception = this.bonReceptionLigneSvc.mapToBonReceptionLigneLitigeList(this.lignesBonReception);

      const lignesBonReceptionToSend = _cloneDeep(this.lignesBonReception);
      lignesBonReceptionToSend.forEach((lbr: BonReceptionLigneDTO) => {
        delete lbr.dxAppellationList;
        delete lbr.dxLitigeList;
        delete lbr.dxMotifNonConfoList;
      });

      this.bonReceptionSvc.updateBonReceptionLignes(this.bonReception?.id, lignesBonReceptionToSend, updateMode).subscribe(response => {
        if (response.resultList) {
          const summary = `Réception enregistrée avec succès.`;
          this.onSave.emit(this.bonReception);
          if (openFactureOnSuccess)
            this.enableDialogBindBill();
          this.displayDialog = false;
          this.onClose.emit(false);
          this.toastSvc.displayToast(MSG_KEY.ROOT, MSG_SEVERITY.SUCCESS, summary);
          this.isModif = false;
        }
      });
    }
  };

  cellTemplate = (container, options) => {
    var noBreakSpace = "\u00A0",
      text = (options.value || []).map((element: any) => {
        return element?.libelle;
      }).join(", ");
    container.textContent = text || noBreakSpace;
    container.title = text;
  };

  onCellPrepared = (event: any) => {
    if (!this.isDisabled()) {

      // si cell est un header
      if (event.rowType === DATAGRID_ROW_TYPES.HEADER) {

        if (event.column.dataField === 'idZds') {
          event.cellElement.style.backgroundColor = UI_COLORS.MANDATORY;
        }
        // si colonne editable
        else if (event.column.allowEditing === true && event.column.dataField !== 'idZds') {
          event.cellElement.style.backgroundColor = UI_COLORS.EDITABLE;
        }
      }
    }
  };

  OnChangeUdpZds = (bonReceptionLigne: BonReceptionLigneDTO) => {
    this.isModif = true;
    bonReceptionLigne.idUdpZds = this.getIdUdpZdsByUdpAndZds(bonReceptionLigne.idZds, this.bonReception.idUniteDeProduction);
  };

  OnChangeOrigine = (bonReceptionLigne: any) => {
    this.isModif = true;
    bonReceptionLigne.origine = _find(this.origineList, (item: OrigineDTO) => {
      return item.id === bonReceptionLigne.idOrigine
    });
  };

  getIdUdpZdsByUdpAndZds = (idZds: number, idUdp: number) => {
    const udpZds: UniteDeProduction__ZoneDeStockageDTO = _find(this.udpZdsList, (item: UniteDeProduction__ZoneDeStockageDTO) => {
      return item.idUniteDeProduction === idUdp && item.idZoneDeStockage === idZds;
    });
    return udpZds.id;
  };

  getQuantiteRecuUF = (cell: BonReceptionLigneDTO) =>
    this.utils.convertPrixUnitaireUdm1ToPrixUnitaireUdm2(cell.quantiteRecue, cell.ratioUniteDeFacturationUniteBase, cell.ratioUniteDeCommandeUniteBase);

  openDialogProduitCarne = ($event: any, bonReceptionLigne: BonReceptionLigneDTO[]) => {
    this.selectedBonReceptionLignes = bonReceptionLigne;
    this.dialogOrigineCarne.openDialog(this.getDefaultOriginesCarne());
  };

  updateOrigines(originesCarne: OrigineCarneDTO) {
    this.isModif = true;
    this.selectedBonReceptionLignes?.forEach(ligne => {
      ligne.naissance = originesCarne?.naissance;
      ligne.elevage = originesCarne?.elevage;
      ligne.abattage = originesCarne?.abattage;
    })
  }

  updateFrancoPort = (data: any): void => {
    this.bonReception.francoDePortCout = data.value;

    this.bonReceptionSvc.updateBonReception(this.bonReception).subscribe(res => {
      this.bonReception.montantTotalHT = res.one.montantTotalHT;
      this.bonReception.montantTotalTTC = res.one.montantTotalTTC;
    });

  };

  enableDialogBindBill = (): void => {
    this.openDialogBindBill = true;
  }

  bindBonReceptionToBill = (): void => {
    this.subBindReception = this.facturationSvc.bindInvoice(this.bonReception.id, this.bonReception.fournisseurId, this.numBill)
      .subscribe((response) => {
        this.openDialogBindBill = false;
        this.toastSvc.displayToast(MSG_KEY.ROOT, MSG_SEVERITY.SUCCESS, `La facture ${this.numBill} est disponible dans l'onglet Factures sur la page des commandes fournisseurs`);
      });
  }

  bindBonReceptionToBillAndNavigate = (): void => {
    this.subBindReception = this.facturationSvc.bindInvoice(this.bonReception.id, this.bonReception.fournisseurId, this.numBill)
      .subscribe((response) => {
        this.openDialogBindBill = false;
        this.navigateToFacture(this.numBill);
      });
  }

  fetchNumbersBill = (): void => {
    if (this.bonReception) {
      this.subNumbersBill = this.facturationSvc.fetchNumbersInvoiceInWaitingValidation(this.bonReception?.site ? this.bonReception?.site.id : this.bonReception?.siteId, this.bonReception?.fournisseurId)
        .subscribe(response => {
          response.resultList.forEach(res => this.numbersBills.push(res));
        });
    }
  }

  onChangeNumBill = ($event): void => {
    const value: string = $event.value;
    if (value === 'Nouveau n° de facture')
      this.initNumBill();
    else
      this.numBill = value;
  }

  initNumBill = () => {
    this.numbersBills = ['Nouveau n° de facture'];
  }

  closeDialogBindBill = (): void => {
    this.numBill = '';
    this.openDialogBindBill = false;
    this.numbersBills = ['Nouveau n° de facture'];
    this.fetchNumbersBill();
  }

  navigateToFacture = (factureNumber: string) => {
    this.navigationSvc.navigateToFacture(this.bonReception.fournisseurId, factureNumber);
  }

  getDefaultOriginesCarne = (): OrigineCarneDTO => {
    if (this.selectedBonReceptionLignes && this.selectedBonReceptionLignes.length > 0)
      return {
        naissance: this.selectedBonReceptionLignes[0].naissance,
        elevage: this.selectedBonReceptionLignes[0].elevage,
        abattage: this.selectedBonReceptionLignes[0].abattage
      }
  };

  isAbleToBindBill = (): boolean => this.hasCommandesFacturesRight
    && !this.numberInvoice
    && (this.bonReception?.receptionStatut.code === this.receptionStatutCode.RECEPTION_PARTIELLE
      || this.bonReception?.receptionStatut.code === this.receptionStatutCode.TERMINE);


  itemClick = (e): void => {
    e.itemData.command();
  }

  canEdit = () => {
    return this?.bonReception?.receptionStatut?.code !== this.receptionStatutCode.RECEPTION_PARTIELLE && this?.bonReception?.receptionStatut?.code !== this.receptionStatutCode.TERMINE
  }

  isActionAvailable = (): boolean => {
    return this.displayEdit && this.canEdit();
  }

  isImageButtonAvailable = (ligne: BonReceptionLigneDTO): boolean => {
    return this.canEdit() || ligne?.lotArticle?.images?.length > 0
  }

  isAddButtonAvailable = (data) => {
    return this.isActionAvailable() && !data.lotManuel
  };

  isDeleteButtonAvailable = (data) => {
    return this.isActionAvailable() && this.displayEdit && data.lotManuel
  }

  onImagePopupClose = (images: ImageBase64DTO[]) => {
    this.isImagePopupOpened = false;
    this.checkIfImagesChanges(images)
    this.selectedBonReceptionLigne.lotArticle.images = images;
  };

  private checkIfImagesChanges(images: ImageBase64DTO[]) {
    let localImages = this.selectedBonReceptionLigne.lotArticle.images;

    if (localImages?.length != images?.length) {
      this.isModif = true;
      return;
    }

    if (localImages && images) {
      const localFileNames = localImages.map(i => i.fileName);
      for (const i of images.map(i => i.fileName)) {
        if (!localFileNames.includes(i)) {
          this.isModif = true;
          return;
        }
      }
    }
  }

  openImageDialog(bonReception: BonReceptionLigneDTO) {
    this.selectedBonReceptionLigne = bonReception;
    this.images = [...bonReception.lotArticle.images];
    this.isImagePopupOpened = true;
  }

  getImageCount(brLigne: BonReceptionLigneDTO) {
    if (brLigne?.lotArticle?.images?.length > 0)
      return "x " + brLigne.lotArticle.images.length;
    return null;
  }
}
