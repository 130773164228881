import {Component, EventEmitter, Input, OnInit, Output,} from "@angular/core";

@Component({
  selector: 'yo-commande-fournisseur-date-filter',
  templateUrl: './commande-fournisseur-date-filter.component.html',
  styleUrls: ['./commande-fournisseur-date-filter.component.scss']
})

export class CommandeFournisseurDateFilter implements OnInit {

  @Output() onValueChange = new EventEmitter;
  @Input() height: String = "auto";

  ngOnInit(): void {}

  onValueChanged = (dates):void => {
    console.log("reusehit", dates)
    let filterDatesLivraisons: Date[];
    if (dates.value[0]) {
      const startDate = this.convertDate(dates.value[0], false);
      let endDate: any;
      if (dates.value[1])
        endDate = this.convertDate(dates.value[1], true);
      else
        endDate = this.convertDate(dates.value[0], true);
      filterDatesLivraisons = [startDate, endDate]
    } else
      filterDatesLivraisons = undefined
    this.onValueChange.emit(filterDatesLivraisons);
  };

  convertDate = (date, isEndDate) => {
    const editedDate = new Date(date)
    if (isEndDate) {
      editedDate.setHours(23);
      editedDate.setMinutes(59);
      editedDate.setSeconds(59);
    }
    return editedDate;
  }

}
