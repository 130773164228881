// DO NOT EDIT THIS FILE !!!! GENERATED DURING BUILD TYPE : Ansible
export const DOL_VERSION='[master]6.1-10392(4c1da29de4)-C04/07/2024-14:59:42-B04/07/2024-15:31:04' ;
export const DOL_VERSION_JSON = {
  dolVersion: "[master]6.1-10392(4c1da29de4)-C04/07/2024-14:59:42-B04/07/2024-15:31:04",
  branch: "master",
  latestTag: "6.1",
  revCount: "10392",
  shortHash: "4c1da29de4",
  longHash: "4c1da29de420bc2412abc0c5d1db993744fa34b2",
  dateCommit: "04/07/2024-14:59:42",
  dateBuild: "04/07/2024-15:31:04",
  buildType: "Ansible",
  } ;
